import React from 'react';
import { graphql } from 'gatsby';
import PortableText from '@sanity/block-content-to-react';
import Layout from "../components/layout";
import SEO from "../components/seo";
import { useTranslation } from "react-i18next"
import styled from 'styled-components';
import Img from 'gatsby-image';
import { getFluidGatsbyImage } from 'gatsby-source-sanity';
import GetInvolved from "../components/getinvolved";

const ArticleStyle = styled.div`
    padding: 90px 100px;
    max-width: 75%;
    margin: auto;
    line-height: 1.5;
    h1 {
        padding-bottom: 0;
        color: #14213D;
    }
    p {
        padding-bottom: 1rem;
        font-size: 1.125rem;
    }

    a {
        color: #219EBC;
        font-weight: bold;
        font-size: 1rem;
    }
    figure {
        padding-bottom: 1.5rem;
        figcaption {
            font-size: .8rem;
            padding-top: .6rem;
        }
    }

`;

const FigureRight = styled.figure`
    width: 40%;
    float: right;
    margin: 20px 0 0 40px;
`;

const FigureLeft = styled.figure`
    width: 40%;
    float: left;
    margin: 20px 40px 0 0;
`;

const FigureCenter = styled.figure`
    width: 100%;
    clear: both;
`
const sanityConfig = {projectId: '7nqypbju', dataset: 'production'}

const imageAlignment = (props) => {
  if (props.node.imageAlign === 'right') {
      return (
          <FigureRight>
            <Img fluid={getFluidGatsbyImage(props.node.asset._ref, {maxWidth: 1000}, sanityConfig)} />
            <figcaption>{props.node.caption}</figcaption>
          </FigureRight>
      )
  } else if (props.node.imageAlign === 'left') {
    return (
        <FigureLeft>
            <Img fluid={getFluidGatsbyImage(props.node.asset._ref, {maxWidth: 1000}, sanityConfig)} />
            <figcaption>{props.node.caption}</figcaption>
        </FigureLeft>
    )
  } else {
      return(
        <FigureCenter>
            <Img fluid={getFluidGatsbyImage(props.node.asset._ref, {maxWidth: 1000}, sanityConfig)} />
            <figcaption>{props.node.caption}</figcaption>
        </FigureCenter>
      )
  }
}

const serializer = {
    types: {
        imageArticle: (props) => (
                <>
                    {imageAlignment(props)}
                </>
        )
    }   
}

const BlogArticle = ({data, alternateLinks}) => {
    const { i18n } = useTranslation("common");
    const enText = data.article._rawBody.filter(content => content.language === "en");
    const phText = data.article._rawBody.filter(content => content.language === "ph");
    console.log(enText);
    return (
      <Layout alternateLink={alternateLinks}>
        <SEO title={i18n.language === 'en' ? data.article.title.en : data.article.title.ph} />
        <ArticleStyle>
            <h1>{i18n.language === 'en' ? data.article.title.en : data.article.title.ph}</h1>
            <h3>{i18n.language === 'en' ? `By ${data.article.author}` : `Ni ${data.article.author}`}</h3>
            {
                i18n.language === 'en' ? 
                <PortableText blocks={enText[0].body} serializers={serializer}/> :
                <PortableText blocks={phText[0].body} serializers={serializer}/>
            }
        </ArticleStyle>
        <GetInvolved />
       </Layout>
    )
  }

export const query = graphql`
    query($id: String!) {
        article: sanityArticles(id: {eq:  $id}) {
            id
            author
            title {
              en
              ph
            }
            _rawBody
            body {
                language
                _type
                _key
                body {
                  _key
                  _type
                  list
                }
              }
          }
    }
`;

    export default BlogArticle;